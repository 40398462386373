import React from "react";
import { Link } from "gatsby";
import ArrowRight from "../arrow-right";

const NotFound = React.memo(({ locale, data, isVideoError }) => {
    return (
        <div className="pageNotFound-container">
            <div className="text-container">
                <h1>{data.titleH1}</h1>

                {isVideoError ? (
                    <p>{data.descriptionVideoError}</p>
                ) : (
                    <p>{data.description}</p>
                )}

                <ul className="list-container">
                    <li className="list-item">
                        <Link to={`/${locale}`}>
                            <ArrowRight />
                            {data.homeTextLabel}
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to={`/${locale}/map`}>
                            <ArrowRight />
                            {data.mapTextLabel}
                        </Link>
                    </li>
                    {data.alternativeLinks.map((link, key) => {
                        return (
                            <li className="list-item" key={key}>
                                <Link to={`/${locale}/${link.slug}`}>
                                    <ArrowRight />
                                    {link.menuTitle}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="video-container">
                <video autoPlay="autoplay" playsInline loop muted>
                    <source
                        src="https://media.earthspeakr.art/video/upload/v1593523141/videos/EarthSpeakr_404.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
        </div>
    );
});

export default NotFound;
