import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({
    description,
    lang,
    meta,
    title,
    shareImageUrl,
    twitterShareImageUrl,
    shareVideoUrl,
    appleItunesAppId,
    googlePlayAppId,
}) {
    const metaDescription = description;

    if (appleItunesAppId) {
        meta.push({
            name: `apple-itunes-app`,
            content: `app-id=${appleItunesAppId}`,
        });
    }

    if (googlePlayAppId) {
        meta.push({
            name: `apple-itunes-app`,
            content: `app-id=${googlePlayAppId}`,
        });
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: "video",
                    property: "og:video",
                    content: shareVideoUrl,
                },
                {
                    name: "image",
                    property: "og:image",
                    content: shareImageUrl,
                },
                {
                    property: "og:image:width",
                    content: "576",
                },
                {
                    property: "og:image:height",
                    content: "1280",
                },
                {
                    name: "twitter:image",
                    content: twitterShareImageUrl
                        ? twitterShareImageUrl
                        : shareImageUrl,
                },
                {
                    name: `twitter:card`,
                    content: shareVideoUrl ? `player` : `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: "earth speakr",
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default SEO;
